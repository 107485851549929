<template>
    <div class="rating-section rating-section__small">
        <div class="rating" v-if="!state" @click="toggle">
            <span></span>
            <a role="button" class="rating__result">Favorite</a>
        </div>

        <div class="rating" v-if="state" @click="toggle">
            <span class="rating__current"></span>
            <a role="button" class="rating__result">Favorited</a>
        </div>
    </div>
</template>
<script>

export default {
    name: 'FavoriteToggle',
    props: {
        id: Number,
        state: Boolean
    },

    inject: ['updateIsFavorite'],

    methods: {
        toggle() {
            this.updateIsFavorite(this.id, !this.state);
        }
    }
};
</script>
