import axios from '@/axios';

const RESOURCE_NAME = '/tags';

export default {

    getList() {
        return axios.get(`${RESOURCE_NAME}/list`)
    },

    addTags(startupsIds, tags) {
        return axios.post(`${RESOURCE_NAME}/add-tags-to-startups`, {startup_ids: startupsIds, tags: tags})
    },

    addTagsByQuery(query, params, sort, tags) {
        return axios.post(`${RESOURCE_NAME}/add-tags-by-query`, {query, params, sort, tags: tags})
    },

    removeTags(startupsIds, tags) {
        return axios.post(`${RESOURCE_NAME}/remove-tags-from-startups`, {startup_ids: startupsIds, tags: tags})
    },

    removeTagsByQuery(query, params, sort, tags) {
        return axios.post(`${RESOURCE_NAME}/remove-tags-by-query`, {query, params, sort, tags})
    },

    getIndexList(data) {
        return axios.post(`${RESOURCE_NAME}/get-index-list`, data)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-tag?id=${id}`)
    },

    getParentsList(id) {
        return axios.get(`${RESOURCE_NAME}/get-parents-list?id=${id}`)
    }
}
