import axios from '@/axios';

const RESOURCE_NAME = '/ecosystem/enterprises';

export default {
    getConnectedEnterprises(data) {
        return axios.post(`${RESOURCE_NAME}/get-connected-enterprises`, data)
    },

    addConnection(data) {
        return axios.post(`${RESOURCE_NAME}/add-connection`, data)
    },

    getConnectedEnterprisesList(data) {
        return axios.post(`${RESOURCE_NAME}/get-connected-enterprises-list`, data)
    },

    getCounts() {
        return axios.get(`${RESOURCE_NAME}/get-counts`)
    },

    addConnectionRequest(data) {
        return axios.post(`${RESOURCE_NAME}/add-connection-request`, data)
    },

    setConnectionRequestStatus(id, status) {
        return axios.post(`${RESOURCE_NAME}/${id}/set-connection-request-status`, {status})
    },

    getDetails(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-details`)
    },

    bulkConnect(data) {
        return axios.post(`${RESOURCE_NAME}/bulk-connect`, data)
    },

    getCommonData(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-common-data`);
    },
}
