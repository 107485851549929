import axios from '@/axios';

const RESOURCE_NAME = '/startup/enterprises';

export default {
    getDetails(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-details`)
    },

    addConnectionRequest(data) {
        return axios.post(`${RESOURCE_NAME}/add-connection-request`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    getCommonData(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-common-data`);
    },

    getActivities(id, sort) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-activities?sort=${sort}`)
    },

    removeInteraction(id, interactionId) {
        return axios.post(`${RESOURCE_NAME}/${id}/remove-interaction?interaction_id=${interactionId}`)
    },

    getNoteEdit(id, noteId) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-note-edit?note_id=${noteId}`)
    },

    saveNote(id, noteId, data) {
        return axios.post(`${RESOURCE_NAME}/${id}/save-note?note_id=${noteId}`, data)
    },

    removeNote(id, noteId) {
        return axios.post(`${RESOURCE_NAME}/${id}/remove-note?note_id=${noteId}`)
    },

    getAvailableRecipients(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-available-recipients`)
    },
}
